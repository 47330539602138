const schema = [
    { name: 'date', index: 0, text: '日' },
    { name: 'AQIindex', index: 1, text: 'AQI指数' },
    { name: 'PM25', index: 2, text: 'PM2.5' },
    { name: 'PM10', index: 3, text: 'PM10' },
    { name: 'CO', index: 4, text: '一氧化碳（CO）' },
    { name: 'NO2', index: 5, text: '二氧化氮（NO2）' },
    { name: 'SO2', index: 6, text: '二氧化硫（SO2）' }
];

export default {
    title: {
        text: "Statistic of Pathway Enrichment",
        left: "center",
    },
    grid: {
        containLabel: true,
        left: 0,
        right: 150,
        top: 50,
        bottom: 75,
        show:true,
        borderColor:"#6e7079"
    },
    tooltip: {
        backgroundColor: 'rgba(255,255,255,0.7)',
        formatter:function (p) {
            return `${p.dimensionNames[0]}：${p.value[p.dimensionNames[0]]}<br/>
                    ${p.dimensionNames[1]}：${p.value[p.dimensionNames[1]]}<br/>
                    ${p.dimensionNames[2]}：${p.value[p.dimensionNames[2]]}`
        }
    },
    dataset: {
        source: []
    },
    xAxis: {
        show:true,
        type: 'value',
        name: '-log10(p.adjust)',
        nameLocation: "center",
        nameGap: 30,
        nameTextStyle: {
            color: "#161616",
            fontSize: 14
        },
        axisLine: { show: true },
        splitLine: { show: true },
        axisTick: { show: true },
        axisLabel: {
            margin: 10
        }
    },
    yAxis: {
        type: 'category',
        axisLabel: {
            width: 200,
            overflow: 'breakAll'//文字超出宽度是否截断或者换行。配置width时有效
        },
        axisLine: { show: true },
        splitLine: { show: true },
    },
    toolbox: {
        show: true,
        feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            saveAsImage: { show: true,backgroundColor:'#FFFFFF',  }
        }
    },
    visualMap: [
        {
            // type:"piecewise",
            left: 'right',
            bottom: 100,
            align: "left",
            dimension: "Number of genes",//指定用数据的『哪个维度』，映射到视觉元素上 
            min: 0,
            max: 0,
            itemWidth: 20,
            itemHeight: 120,
            calculable: true, //是否显示拖拽用的手柄
            text: ['Gene_number'],
            inRange: { //控制选中范围内的内容
                symbolSize: [5, 25], //控制选中范围内的球的大小
                color: ['#ffa8a8', '#fa5252']
            },
            controller: {//inRange,outOfRange控制器的样式控制
                outOfRange: { //控制选中范围外的内容
                    symbolSize: [5, 25],
                    color: ['rgba(173, 173, 173,.5)']
                },
            }
        },
        {
            left: 'right',
            top: '15%',
            align: "left",
            dimension: "-log10(p.adjust)",
            min: 0,
            max: 0,
            itemWidth: 20,
            itemHeight: 120,
            calculable: true,
            text: [],
            inRange: {
                color: ['#460558', '#404588', '#2a788e', '#4ac16d', '#f4e772']
            },
            outOfRange: {
                color: ['rgba(173, 173, 173,.5)']
            }
        }
    ],
    dataZoom: [
        // {
        //     show: true,
        //     start: 0,
        //     end: 50,
        //     height: 20,
        //     xAxisIndex:0,
        //     showDataShadow: false,
        // },
        {
            show: true,
            start: 0,
            end: 40,
            yAxisIndex: 0,
            filterMode: 'empty',
            width: 20,
            height: '80%',
            showDataShadow: false,
            right:105
        }
    ],
    series: [
        {
            type: 'scatter',
            encode: {//定义 data 的哪个维度被编码成什么
                x: '-log10(p.adjust)',
                y: 'Description',
                tooltip: ['-log10(p.adjust)', 'Description', "Number of genes"]
            },
        }
    ]
};