<template>
    <div class="kegg-enrichment">
        <div class="table-download">
            <a-tag color="orange"  @click="downloadFile(csvUrl)">
                <a-icon type="cloud-download"/>
              download csv
            </a-tag>
        </div>

        <a-table
            :columns="columns"
            :data-source="keegData"
            :loading="tableLoading"
            rowKey="ID"
            :scroll="{x:true}"
        >
            <div
                slot="filterDropdown"
                slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                style="padding: 0.5rem;"
            >
                <a-input
                    v-ant-ref="c => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    size="small"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                >Search</a-button>
                <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                >Reset</a-button>
            </div>
            <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template slot="customRender" slot-scope="text, record, index, column">
                <a v-if="searchText && searchedColumn === column.dataIndex" :href="'https://www.kegg.jp/pathway/' + text"
                   target="_blank">
                    <template
                        v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                        <mark
                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                            :key="i"
                            class="highlight"
                        >{{ fragment }}</mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </a>
                <template v-else><a :href="'https://www.kegg.jp/pathway/' + text"
                                    target="_blank">{{ text }}</a></template>
            </template>
            <template slot="descriptionRender" slot-scope="text, record, index, column">
                <span v-if="searchText && searchedColumn === column.dataIndex">
                    <template
                        v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                        <mark
                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                            :key="i"
                            class="highlight"
                        >{{ fragment }}</mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
                <template v-else>{{ text }}</template>
            </template>
        </a-table>

        <div class="conditions">
            <span>过滤条件</span>
            <div class="con">
                <a-form layout="inline">
                    <a-form-item label="pvalue">
                        <a-input-number size="large" v-model="filterValue" />
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="bottom">
                            <template slot="title">
                                <span>将重新生成图</span>
                            </template>
                            <a-button
                                class="btn"
                                type="primary"
                                @click="filterDiff"
                                :loading="chartLoading"
                            >filter</a-button>
                        </a-tooltip>
                    </a-form-item>
                </a-form>
            </div>
        </div>

        <div class="charts-list">
            <a-spin tip="Loading..." :spinning="chartLoading">
                <Chart
                    echarts-id="kegg-enrichment-diff"
                    height="75vh" width="60rem"
                    :option="keegEnrichmentDiff"
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
import Chart from "@/components/Chart.vue";
import KeggEnrichmentPoint from "@/chartsOptions/keggEnrichmentPoint";
import { parseCsv, downloadFile } from "@/utils/csv.js"

export default {
    name: "KeggEnrichment",
    data() {
        return {
            filterValue: 0.5, //筛选标准
            csvUrl: "",
            keegData: [],
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'ID',
                    width: 110,
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender'
                    },
                    onFilter: (value, record) => record.ID.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            });
                        }
                    }
                },
                {
                    title: 'Description',
                    dataIndex: 'Description',
                    ellipsis: true,
                    width: 320,
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'descriptionRender'
                    },
                    onFilter: (value, record) => record.Description.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            });
                        }
                    }
                },
                {
                    title: 'GeneRatio',
                    dataIndex: 'GeneRatio',
                    width: 100
                },
                {
                    title: 'BgRatio',
                    dataIndex: 'BgRatio',
                    width: 100
                },
                {
                    title: 'pvalue',
                    dataIndex: 'pvalue',
                    sorter: (a, b) => a.pvalue - b.pvalue,
                    customRender: (text, row, index) => text.toExponential(3),
                    width: 120
                },
                {
                    title: 'p.adjust',
                    dataIndex: 'p.adjust',
                    sorter: (a, b) => a['p.adjust'] - b['p.adjust'],
                    customRender: (text, row, index) => text.toExponential(3),
                    width: 120
                },
                {
                    title: 'qvalue',
                    dataIndex: 'qvalue',
                    sorter: (a, b) => a.qvalue - b.qvalue,
                    customRender: (text, row, index) => text.toExponential(3),
                    width: 120
                },
                {
                    title: 'geneID',
                    dataIndex: 'geneID',
                    ellipsis: true,
                    customRender: (text, row, index) => text.split("/").join(",")
                },
                {
                    title: 'Count',
                    dataIndex: 'Count',
                    width: 80
                }
            ],
            /* 表格搜索 */
            searchText: '',
            searchInput: null,
            searchedColumn: '',
            /* 表格搜索 End */
            chartLoading: false,
            tableLoading: true,
            keegEnrichmentDiff: null
        };
    },
    components: { Chart },
    props: {
        task: {
            type: Object,
            default: {}
        }
    },
    mounted() {
        this.csvUrl = this.common.source_url + "/" + this.task.result.path + "/data.kegg.enrichment.csv";
        parseCsv(this.csvUrl, (csv) => {
            this.keegData = csv;
            this.tableLoading = false;

            //进行筛选分析生成图表
            this.filterDiff();
        })
    },
    methods: {
        downloadFile,
        //筛选分析
        filterDiff() {
            //for速度快
            let max = 0, genesNum = 0;
            KeggEnrichmentPoint.dataset.source = [];
            for (let i = 0, c = this.keegData.length; i < c; i++) {
                const item = this.keegData[i];
                //显著性一般会用qvalue或者pvalue作为筛选标准，小于某个值表示显著
                const remarkable = item.pvalue < this.filterValue;

                if (remarkable) {
                    KeggEnrichmentPoint.dataset.source.push({ Description: item.Description, "-log10(p.adjust)": -Math.log10(item['p.adjust']), "Number of genes": item.Count });
                    max = -Math.log10(item['p.adjust']) > max ? -Math.log10(item['p.adjust']) : max;
                    genesNum = item.Count > genesNum ? item.Count : genesNum;
                }
            }

            KeggEnrichmentPoint.visualMap[0].max = genesNum;
            KeggEnrichmentPoint.visualMap[1].max = Math.ceil(max);

            this.generateChart();
        },
        generateChart() {
            KeggEnrichmentPoint.visualMap[1].text = ['-log10(p.adjust)'],
                this.keegEnrichmentDiff = KeggEnrichmentPoint;
        },
        //表格搜索
        handleSearch(selectedKeys, confirm, dataIndex) {
            confirm();
            this.searchText = selectedKeys[0];
            this.searchedColumn = dataIndex;
        },
        //重置搜索
        handleReset(clearFilters) {
            clearFilters();
            this.searchText = '';
        },
    },
}
</script>

<style lang="scss" scoped>
.kegg-enrichment{
    position: relative;
    top: -20px;
}

.table-download{
    width: 100%;
    text-align: right;
    margin-top: 21px;

    .ant-tag{
        cursor: pointer;
        height: 33px;
        line-height: 33px;
        font-size: 16px;
    }
}

.ant-table-wrapper{
    margin-top: 20px !important;
}

.conditions {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-bottom: 30px;
    height: 2rem;

    span {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        margin-right: 1rem;
    }

    .con {
        .ant-form {
            font-size: 0.8rem;
            font-weight: 600 !important;
            color: #000000;
        }

        .btn {
            width: 107px;
            height: 40px;
            background: rgba(9, 127, 53, 0.12);
            border-radius: 5px 5px 5px 5px;
            opacity: 1;
            border: 1px solid #097F35;

            ::v-deep span {
                font-size: 16px;
                font-weight: 400;
                color: #097F35;
            }
        }
    }
}
</style>