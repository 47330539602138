//go富集 柱状图
export default {
    title: {
        left: 'center',
        text: '',
    },
    tooltip: {
        position: 'top',
        formatter:function (p) {
            return `${p.dimensionNames[0]}：${p.value[p.dimensionNames[0]]}<br/>
                    ${p.dimensionNames[1]}：${p.value[p.dimensionNames[1]]}<br/>
                    ${p.dimensionNames[2]}：${p.value[p.dimensionNames[2]]}`
        }
    },
    dataset: {
        dimensions: ['-log10(p.adjust)', 'Description', "Number of genes"],
        source: []
    },
    grid: {
        left:220,
        right: 100
    },
    xAxis: {
        type: "value",
        name: "-log10(p.adjust)",
        nameLocation: "center",
        nameTextStyle: {
            color: "#161616",
            fontSize: 14
        },
        nameGap: 25,
        axisLine: {
            show: true
        },
        axisTick: {
            show: true
        }
    },
    yAxis: {
        type: 'category',
        name: "GO term",
        nameLocation: "center",
        nameTextStyle: {
            color: "#161616",
            fontSize: 14,
        },
        nameGap: 200,
        axisLabel: {
            width: 180,
            overflow: 'breakAll'//文字超出宽度是否截断或者换行。配置width时有效
        }
    },
    toolbox: {
        show: true,
        feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true,backgroundColor:'#FFFFFF', }
        }
    },
    visualMap: {
        type: "continuous",
        calculable: false, //是否显示拖拽用的手柄
        show: false,
        outOfRange: { //控制选中范围外的内容
            color: ['rgba(173, 173, 173,.5)']
        },
        top: 25,
        left: 'center',
        orient: "horizontal",
        min: 0,
        max: 0,
        itemWidth: 15,
        itemHeight: 120,
        dimension: "-log10(p.adjust)",
        inRange: {
            color: ['#FFDD00']
            // color: ['#65B581', '#FFCE34', '#FD665F']
        }
    },
    dataZoom: [
        // x轴滑动块
        // {
        //     show: true,
        //     start: 20,
        //     end: 100
        // },
        {
            show: true,
            start: 0,
            end: 10,
            yAxisIndex: 0,
            filterMode: 'empty',
            width: 30,
            height: '80%',
            showDataShadow: false,
            left: '93%'
        }
    ],
    series: [{
        type: 'bar',
        encode: {//定义 data 的哪个维度被编码成什么
            x: '-log10(p.adjust)',
            y: 'Description',
            tooltip: ['-log10(p.adjust)', 'Description', "Number of genes"]
        },
        label: {
            show: true,
            position: "right",
            formatter:function (params) {
                return params.value[params.dimensionNames[params.encode.x[0]]].toFixed(3)
            }
        }
    }]
}